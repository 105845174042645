// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-books-moonwalking-js": () => import("./../../../src/pages/books/moonwalking.js" /* webpackChunkName: "component---src-pages-books-moonwalking-js" */),
  "component---src-pages-books-stumbling-js": () => import("./../../../src/pages/books/stumbling.js" /* webpackChunkName: "component---src-pages-books-stumbling-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mems-js": () => import("./../../../src/pages/mems.js" /* webpackChunkName: "component---src-pages-mems-js" */),
  "component---src-pages-papers-foodflock-js": () => import("./../../../src/pages/papers/foodflock.js" /* webpackChunkName: "component---src-pages-papers-foodflock-js" */),
  "component---src-pages-projects-smart-mirror-js": () => import("./../../../src/pages/projects/smartMirror.js" /* webpackChunkName: "component---src-pages-projects-smart-mirror-js" */)
}

